//import { lazy } from 'react'
import { lazy } from 'react'
import { getUserData } from '../../auth/utils'

// ** Document title
const TemplateTitle = 'Wetu'

// ** Default Route
const DefaultRoute = '/Dashboard'

const userData = getUserData()

// ** Merge Routes
const Routes = [
  {
    path: '/*',
    component: lazy(() => import('@src/views/pages/IFramedContent')),
    iframeConfigs: [
      {
        id: 'dashboard',
        path: '/Dashboard',
        src: userData ? Object.keys(userData).length !== 0 && userData.AccountType && userData.AccountType === "Operator" ? `${process.env.REACT_APP_BLOG_URL}/intermediaries-page.php/` : `${process.env.REACT_APP_BLOG_URL}/suppliers-page.php/` : ''  
      },
      {
        id: 'contacts',
        path: '/Contacts',
        src: `https://${process.env.REACT_APP_CRM_DOMAIN}/#/contacts/index?return_module=Contacts&return_action=DetailView`
      },
      {
        id: 'profiles',
        path: '/Profiles',
        src: `${process.env.REACT_APP_PROFILE_UI_URL}/home`
      },
      {
        id: 'specials-manager',
        path: '/SpecialsManager',
        src: `https://${process.env.REACT_APP_MONOLITH_URL}/Specials`
      },
      {
        id: 'specials-portal',
        path: '/Specials/Search',
        src: `https://${process.env.REACT_APP_MONOLITH_URL}/Specials/Search`
      },
      {
        id: 'itinerary-builder',
        path: '/ItineraryBuilder',
        src: `https://${process.env.REACT_APP_MONOLITH_URL}/Builder`
      },
      {
        id: 'itinerary-builder-personal',
        path: '/ItineraryBuilder/Personal',
        src: `https://${process.env.REACT_APP_MONOLITH_URL}/Builder/Browse/Personal`
      },
      {
        id: 'itinerary-builder-sample',
        path: '/ItineraryBuilder/Sample',
        src: `https://${process.env.REACT_APP_MONOLITH_URL}/Builder/Browse/Sample`
      },
      {
        id: 'itinerary-builder-day-component',
        path: '/ItineraryBuilder/DayComponent',
        src: `https://${process.env.REACT_APP_MONOLITH_URL}/Builder/Browse/DayComponent`
      },
      {
        id: 'itinerary-builder-multiday-component',
        path: '/ItineraryBuilder/MultiDayComponent',
        src: `https://${process.env.REACT_APP_MONOLITH_URL}/Builder/Browse/Component`
      },
      {
        id: 'itinerary-builder-daytours',
        path: '/ItineraryBuilder/DayTours',
        src: `https://${process.env.REACT_APP_MONOLITH_URL}/Builder/Browse/DayTour`
      },
      {
        id: 'itinerary-builder-multidaytours',
        path: '/ItineraryBuilder/MultiDayTours',
        src: `https://${process.env.REACT_APP_MONOLITH_URL}/Builder/Browse/MultiDayTours`
      },
      {
        id: 'itinerary-builder-pubdaytours',
        path: '/ItineraryBuilder/PublishedDayTours',
        src: `https://${process.env.REACT_APP_MONOLITH_URL}/Builder/Browse/PublishedDayTours`
      },
      {
        id: 'itinerary-builder-pubmultidaytours',
        path: '/ItineraryBuilder/PublishedMultiDayTours',
        src: `https://${process.env.REACT_APP_MONOLITH_URL}/Builder/Browse/PublishedMultiDayTours`
      },
      {
        id: 'calendar',
        path: '/Calendar',
        src: `https://${process.env.REACT_APP_MONOLITH_URL}/Calendar`
      },
      {
        id: 'analytics',
        path: '/Analytics',
        src: `https://${process.env.REACT_APP_MONOLITH_URL}/Home/AnalyticsContent`
      },
      {
        id: 'catalogue-manager',
        path: '/Catalogue',
        src: `https://${process.env.REACT_APP_MONOLITH_URL}/Rack/Build/Catalogue`
      },
      {
        id: 'personal-details',
        path: '/PersonalDetails',
        src: `https://${process.env.REACT_APP_MONOLITH_URL}/Admin/Users/Edit/${userData?.UserId}?isPersonalDetails=true`
      },
      {
        id: 'status-page',
        path: '/StatusPage',
        src: `https://status.${process.env.REACT_APP_MONOLITH_URL}/`
      },
      {
        id: 'account',
        path: '/Account',
        src: `https://${process.env.REACT_APP_MONOLITH_URL}/Admin/Operators/Edit/${userData?.AccountId}`
      },
      {
        id: 'connect-bookings',
        path: '/Connect',
        src: `https://${process.env.REACT_APP_MONOLITH_URL}/Connect`
      },
      {
        id: 'wetu-accounts-operators',
        path: '/Operators',
        src: `https://${process.env.REACT_APP_MONOLITH_URL}/Admin/Operators?tab=operators`
      },
      {
        id: 'wetu-accounts-products',
        path: '/Products',
        src: `https://${process.env.REACT_APP_MONOLITH_URL}/Admin/Operators?tab=products`
      },
      {
        id: 'marketers',
        path: '/Marketers',
        src: `https://${process.env.REACT_APP_MONOLITH_URL}/Admin/Operators?tab=marketers`
      },
      {
        id: 'travel-agents',
        path: '/TravelAgents',
        src: `https://${process.env.REACT_APP_MONOLITH_URL}/Admin/Operators?tab=travelagents`
      },
      {
        id: 'micro-operators',
        path: '/MicroOperators',
        src: `https://${process.env.REACT_APP_MONOLITH_URL}/Admin/Operators?tab=micro`
      },
      {
        id: 'trials',
        path: '/Trials',
        src: `https://${process.env.REACT_APP_MONOLITH_URL}/Admin/Operators?tab=trials`
      },
      {
        id: 'search',
        path: '/Search',
        src: `https://${process.env.REACT_APP_MONOLITH_URL}/Admin/Operators?tab=find`
      },
      {
        id: 'reports-tools',
        path: '/ReportsAndTools',
        src: `https://${process.env.REACT_APP_MONOLITH_URL}/Admin/Operators?tab=reports`
      },
      {
        id: 'ibrochure-search',
        path: '/iBrochure/Search',
        src: `https://${process.env.REACT_APP_MONOLITH_URL}/Home/IBrochure_Search`
      },
      {
        id: 'consultant-portal',
        path: '/ConsultantPortal',
        src: `https://${process.env.REACT_APP_MONOLITH_URL}/ConsultantPortal`
      }
    ]
  }
]

export { DefaultRoute, TemplateTitle, Routes }